import { ChainId } from '@pancakeswap/chains'
import { supportedChainId } from '@pancakeswap/farms'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_FARMS = supportedChainId
export const LIQUID_STAKING_SUPPORTED_CHAINS = [
  ChainId.OMNIOMEGA,
  ChainId.ETHEREUM,
  ChainId.BSC_TESTNET,
  ChainId.ARBITRUM_GOERLI,
]
export const FIXED_STAKING_SUPPORTED_CHAINS = [ChainId.OMNIOMEGA]

export const V3_MIGRATION_SUPPORTED_CHAINS = [ChainId.OMNIOMEGA, ChainId.ETHEREUM]
export const V2_BCAKE_MIGRATION_SUPPORTED_CHAINS = [ChainId.OMNIOMEGA]

export const SUPPORT_CAKE_STAKING = [ChainId.OMNIOMEGA, ChainId.BSC_TESTNET]
