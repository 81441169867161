import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";
export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  // {
  //   label: t("Ecosystem"),
  //   items: [
  //     {
  //       label: t("Trade"),
  //       href: "https://pancakeswap.finance/swap",
  //     },
  //     {
  //       label: t("Earn"),
  //       href: "https://pancakeswap.finance/farms",
  //     },
  //     {
  //       label: t("Game"),
  //       href: "https://pancakeswap.games/",
  //     },
  //     {
  //       label: t("NFT"),
  //       href: "https://pancakeswap.finance/nfts",
  //     },
  //     {
  //       label: t("Tokenomics"),
  //       href: "https://docs.pancakeswap.finance/governance-and-tokenomics/cake-tokenomics",
  //     },
  //     {
  //       label: t("CAKE Emission Projection"),
  //       href: "https://analytics.pancakeswap.finance/",
  //     },
  //     {
  //       label: t("Merchandise"),
  //       href: "https://merch.pancakeswap.finance/",
  //     },
  //   ],
  // },
  // {
  //   label: "Business",
  //   items: [
  //     {
  //       label: t("Farms and Syrup Pools"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/syrup-pools-and-farms",
  //     },
  //     {
  //       label: t("IFO"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/initial-farm-offerings-ifos",
  //     },
  //     {
  //       label: t("NFT Marketplace"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/nft-market-applications",
  //     },
  //   ],
  // },
  {
    label: t("DominusSwap"),
    items: [
      {
        label: t("Trade"),
        href: "#",
      },
      {
        label: t("Pool"),
        href: "#",
      },
    ],
  },
  {
    label: t("Follow Us"),
    items: [
      {
        label: t("Twitter"),
        href: "https://x.com/dominusswap",
      },
      {
        label: t("Discord"),
        href: "#",
      },
      {
        label: t("Telegram"),
        href: "#",
      },
    ],
  },
  {
    label: t("About"),
    items: [
      {
        label: t("Docs"),
        href: "#",
      },
      {
        label: t("Community support"),
        href: "#",
      },
    ],
  },
];
